<template>
  <div class="accordion" role="tablist">
    <b-card v-for="(faq, index) in Faqs" :key="faq.question" no-body>
      <b-card-header header-tag="header" role="tab" v-b-toggle="'accordion-' + index" variant="light">
      {{faq.question}}
      </b-card-header>
      <b-collapse :id="'accordion-'+ index" visible accordion="faqs" role="tabpanel">
        <b-card-body>
          <b-card-text v-html="faq.answer"></b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import faqs from '@/assets/faqs.json'
export default {
  name: 'FaqsSection',
  data() {
    return {
      Faqs: faqs,
    }
    }
}
</script>
<style>
.btn-block {
  text-align: left!important;
}
.card-header {
  padding: 1rem 1.25rem!important;
  cursor: pointer;
}
.not-collapsed {
  font-weight: 600;
}
</style>
<template>
  <ais-instant-search index-name="Products" :search-client="searchClient">
    <div class="container product-container" id="products">
    <div class="row heading-row">
      <div class="col-sm-12 text-center">
        <h2>{{ heading }}</h2>
        <p>We have a ton of products that we love and here are some of our top picks! Be sure to check out our <router-link to="/products">products page</router-link> to view our whole list!</p>
      </div>
    </div>
    <div class="search-container">
      <ais-configure :hitsPerPage="6" />
      <ais-hits>
        <template v-slot:item="{ item }">
          <div v-if="item.category" :class="'product-type ' + item.category">
            {{ item.category }}
          </div>
          <div class="product-image">
            <a :href="item.link" :target="item.target" :title="item.name" class="product-link">
              <img class="img-fluid" :src="item.image" loading="lazy" width="200" height="200" :alt="item.name"
            /></a>
          </div>
          <div class="product-body">
            <h4 class="product-title">{{ item.name }}</h4>
          </div>
          <div class="product-link">
            <a :href="item.link" :target="item.target" :title="item.name" class="product-link">View Product</a>
          </div>
        </template>
      </ais-hits>
    </div>
    <div class="view-all-link">
    <router-link to="/products" class="text-button justify-content-center">View More<b-icon icon="arrow-right"/></router-link>
    </div>
    </div>
  </ais-instant-search>
</template>



<script>
import algoliasearch from "algoliasearch/lite";

export default {
  name: 'HomeProducts',
  data() {
    return {
      heading: "My Favorite Products",
      body: "We have a ton of products that we love and here are some of our top picks! Be sure to check out our products page to view our whole list!",
      searchClient: algoliasearch(
        "FZTVNY7QVX",
        "61b9df818f94a9a5f5934dc1441587b0"
      ),
    };
  },
};
</script>
<style>
.product-container {
  margin-top: 4em;
  margin-bottom: 4em;
}

.ais-Hits-list {
  display: grid;
  grid-gap: .6em;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 0;
  margin-bottom: 1em;
  flex-direction: row;
  flex-wrap: wrap;
}
.ais-Hits-item {
  position: relative;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #f4f4f4;
  gap: 1em;
  border-radius: 10px;
}
.product-type {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 0.7em;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 50px;
  z-index: 999;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
}
.featured {
color: gold;
margin-left: 5px
}
.treats {
  background-color: var(--orange);
}
.accessories {
  background-color: var(--green);
}
.grooming {
  background-color: var(--purple);
}
.home {
  background-color: var(--red);
}
.toys {
  background-color: var(--blue);
}
.puppy {
  background-color: var(--teal);
}
.product-image img {
  max-height: 200px;
  max-width: 200px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  -o-object-fit: contain;
  object-fit: contain;
  mix-blend-mode: multiply;
}
.product-body {
  margin: 1em auto;
  max-width: 400px;
}
.product-body h4 {
  font-size: 1.4em;
  text-transform: capitalize;
}

.view-all-link {
margin-top: 2em;}
@media (max-width: 767px) {
  .ais-Hits-list {
      grid-template-columns: repeat(2, 1fr);

  }
  .ais-Hits-item {
        padding: 2em 1em;
  }
.product-type {
position: initial;
}
.product-body h4 {
  font-size: 1em
}
}
</style>

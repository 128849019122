<template>
  <div class="homepage">
<HomeHero/>
<HomeAbout/>
<HomeProducts/>
<InstaCarousel/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHero from '@/components/HomeHero.vue'
import HomeAbout from '@/components/HomeAbout.vue'
import HomeProducts from '@/components/HomeProducts.vue'
import InstaCarousel from '@/components/InstaCarousel.vue'

export default {
  name: 'pageHome',
  components: {
    HomeHero,
    HomeAbout,
    HomeProducts,
    InstaCarousel,
  },
      metaInfo: {
      title: 'Home',
      meta: [
      { vmid: 'description', name: 'description', content: 'Mozzie the Mini Dood, an F1B Mini Goldendoodle sharing my favorite stuff!' },
    ]
    }
}
</script>
<style scoped>

</style>

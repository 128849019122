<template>
  <div class="partners">
    <PageHeader heading="Our Partners" subheading="Some companies are absolutely worth raving about. Check out some of the companies we love and use our discount code to save on your purchases!"/>
    <div class="container">
      <div class="partner-list">
      <div class="row partner-row" v-for="partner in partners" :key="partner.name">
        <div class="col-sm-3">
         <img :src="partner.image" class="img-fluid" :alt="partner.name">
        </div>
        <div class="col-sm-9">
          <div class="partner-name-logo">
          <h3>{{partner.name}} - {{partner.summary}}</h3>
          </div>
          <div v-html="partner.body"></div>
          <p v-if="partner.discount" class="partner-discount"> {{partner.discount}}</p>
          <p v-if="partner.code" class="partner-code"><strong>Discount Code: </strong><span class="code"
          v-clipboard:copy="partner.code"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError">{{partner.code}}
          <b-icon icon="files"></b-icon></span>
           </p>
          <a :href="partner.link" class="partner-link text-button">Shop {{partner.name}}<b-icon icon="arrow-right"></b-icon></a>
        </div>
    </div>
      </div>
  </div>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
//import { butter } from "@/buttercms"
import partners from '@/assets/partners.json'

export default {
  name: "pagePartners",
  components: {
    PageHeader,
  },
 data() {
    return {
      partners: partners
    }
  } ,
  methods: {
    onCopy: function (e) {
      alert('Promo Code copied: ' + e.text)
    },
    onError: function (e) {
      alert('Failed to copy texts')
    },
  /*getPartners() {
     var params = {
    "page": "1",
    "page_size": "10"
}
    butter.content.retrieve(['partners'],params)
              .then((res) => {
          console.log(res.data)
            this.partners = res.data
          }).catch((res) => {
            console.log(res)
          })
  }*/
  },
created() {
      this.getPartners()
    },
        metaInfo: {
      title: 'Our Partners & Discounts',
      meta: [
      { vmid: 'description', name: 'description', content: 'Use our Discount Codes to get exclusive discounts from our partners like West and Willow Custom Pet Portraits.' },
    ]
    }
 }
</script>
<style scoped>
.partner-list {
  margin: 6em 0;
}
.partner-row {
  margin-bottom: 6em;
}
.partner-list .col-sm-3 {
  padding-right: 2em;
  margin-bottom: 2em;
}
.partner-list .col-sm-9 {
  margin-top: .5em
  }

  .partner-name-logo img{
max-height: 50px;
object-fit: contain;
margin-bottom: 2rem;
    }
.partner-discount {
  font-weight: bold;
  margin-bottom: 5px
}
.code {
  cursor: pointer;
}
.code svg{
  margin-left: .5em;
}
</style>
<template>
  <div class="container">
 <b-navbar toggleable="md" type="light">
<b-navbar-brand>
  <router-link to="/">
  <strong>mozzie</strong>theminidood</router-link>
</b-navbar-brand>
  <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
    <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item v-for="nav in nav" :key="nav.name" :to="nav.path"  >{{nav.name}}</b-nav-item>
        <b-nav-item href="https://instagram.com/mozzietheminidood" target="_blank"><b-icon icon="instagram"></b-icon></b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
export default {
  name: 'SiteHeader',
  data() {
    return {
    brand: "@mozzietheminidood",
    nav: [
      {
        name: "About Me",
        path: "/about",
        hash: ""
      },
      {
        name: "Products",
        path: "/products",
        hash: "",
      },
      {
        name: "Partners",
        path: "/partners",
        hash: "",
      },
      {
        name: "Blog",
        path: "/blog",
        hash: "",
      },
    ]
  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  align-items: center;
} 
.navbar a {
  color: #2c3e50!important;
}
.navbar-nav {
  gap: .5em;
}
.show .navbar-nav {
  align-items: center;
}
.nav-item svg {
  line-height:1em;
}
.navbar-brand {
  padding-right: 1.5em;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import InstantSearch from 'vue-instantsearch';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import VueMeta from 'vue-meta'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)
Vue.use(InstantSearch);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
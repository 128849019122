<template>
  <div class="footer">
    <div class="container footer-container">
        <p class="instagram"><a href="https://instagram.com/mozzietheminidood" target="_blank"><b-icon icon="instagram"/> @mozzietheminidood</a></p>
        <ul class="footer-nav">
          <li v-for="item in footerNav" :key="item.name">
            <router-link :to="item.path">{{item.name}}</router-link>
          </li>
        </ul>
        <p class="disclosure">This site contains affiliate links and we may receive commissions from purchases made from through those links. These are all verified products we personally use, and highly recommend.</p>
      <p class="copyright">© {{new Date().getFullYear()}} mozzietheminidood</p>
</div>
</div>
</template>

<script>
export default {
  name: 'SiteFooter',
  data() {
    return {
      footerNav: [
      {
        name: "Home",
        path: "/",
      },
      {
        name: "About",
        path: "/about",
      },
      {
        name: "Products",
        path: "/products",
      },
      {
        name: "Partners",
        path: "/partners",
      },
      {
        name: "Blog",
        path: "/blog",
      },
    ]
  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  width: 100%;
  margin-top: 8em;
  padding: 2em 0 1em 0;
  background-color: #f4f4f4;
  min-height: 100px;
  color: #2c3e50;
  text-align: center;
}
.footer-container {
  flex-direction: column
}
.disclosure {
  font-size: .9rem;
  max-width: 800px;
  margin: 0 auto;
}
.copyright {
  margin-top: 1rem;
  font-size: .9em;
}
.instagram a {
  color:#2c3e50!important;
}
.butter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.butter img {
  width: 130px;
}
.footer-nav {
      display: flex;
    justify-content: center;
    list-style: none;
    gap: 1em;
    padding: 0;
    font-size: .9em;
}
.footer-nav a {
  color: #2c3e50 !important;
}
</style>

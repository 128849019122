<template>
  <div class="blog-post-page">
    <PageHeader :heading="metaTitle" :subheading="metaDescription" />
    <div class="container">
      <div class="blog-post">
        <div class="row blog-post-row">
          <div class="col-sm-12">
            <div class="post-image">
              <img
                :src="post.fields.featuredImage.fields.file.url"
                class="img-fluid"
                :alt="post.fields.title"
              />
            </div>
            <div class="post-body" v-html="postContent">>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import { client } from "@/contentful"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';



export default {
  name: "blogPost",
  components: {
    PageHeader,
  },
  data() {
    return {
      post: {},
      postContent: [],
      metaTitle: "",
      metaDescription: "",
      featuredImage: "",
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.metaDescription,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.metaTitle,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.metaDescription,
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.featuredImage,
        },
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "en_US",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://mozzietheminidood.com" + this.$route.path,
        },
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.metaTitle,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.metaDescription,
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.featuredImage,
        },
      ],
    };
  },
  methods: {
    
    getPost() {
        client.getEntries({
          content_type: 'blogPost',
          'fields.slug': this.$route.params.slug
        })
        .then((res) => {
        //console.log(res.items[0]);
        this.post = res.items[0];
        this.metaTitle = res.items[0].fields.title;
        this.metaDescription = res.items[0].fields.metaDescription;
        this.featuredImage = res.items[0].fields.featuredImage.fields.file.url;
        const rawRichTextField = res.items[0].fields.postContent;
        return documentToHtmlString(rawRichTextField);
  })
  .then((renderedHtml) => {
          // do something with html, like write to a file
          console.log(renderedHtml);
          this.postContent = renderedHtml;
        })
      }
  },
  watch: {
    $route(to, from) {
      this.getPost();
    },
  },
  created() {
    this.getPost();
  },
};
</script>
<style>
.blog-post {
  max-width: 800px;
  margin: 0 auto;
}
.post-image img {
  margin-top: 4em;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}
.post-body {
  margin-top: 2em;
}
.blog-post ol,
.blog-post ul {
  margin-left: 30px !important;
  font-size: 1.2em !important;
}
.post-body img {
  width: 100%;
  height: auto;
}
.post-meta-description h2 {
  font-size: 1.4em;
  margin-top: 2em;
}
</style>
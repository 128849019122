var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{attrs:{"index-name":"Products","search-client":_vm.searchClient}},[_c('app-loading-indicator'),_c('div',{staticClass:"container product-container",attrs:{"id":"products"}},[_c('div',{ref:"filtered",staticClass:"search-container"},[_c('ais-configure',{attrs:{"hitsPerPage":100}}),_c('div',{staticClass:"facets"},[_c('ais-refinement-list',{attrs:{"attribute":"category","sort-by":['isRefined']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
return [_c('ul',{staticClass:"ais-RefinementList-list"},_vm._l((items),function(item){return _c('li',{key:item.value,class:item.isRefined
                  ? item.label + ' ais-RefinementList-item active'
                  : 'ais-RefinementList-item',on:{"click":function($event){refine(item.value);_vm.goto('filtered')}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)]}}])}),_c('ais-clear-refinements',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var canRefine = ref.canRefine;
                  var refine = ref.refine;
return [(canRefine)?_c('div',{staticClass:"clear-refinements-container",on:{"click":function($event){return _vm.goto('filtered');}}},[_c('button',{staticClass:"ais-RefinementList-item clear-refinements",on:{"click":refine}},[_vm._v("Clear")])]):_c('div')]}}])})],1),_c('div',{staticClass:"product-list"},[_c('ais-infinite-hits',{scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [(item.category)?_c('div',{class:'product-type ' + item.category},[_vm._v(" "+_vm._s(item.category)+" ")]):_vm._e(),_c('div',{staticClass:"product-image"},[_c('b-link',{staticClass:"product-link",attrs:{"href":item.link,"target":item.target}},[_c('img',{staticClass:"img-fluid",attrs:{"src":item.image,"loading":"lazy","width":"200","height":"200","alt":item.name}})])],1),_c('div',{staticClass:"product-body"},[_c('h4',{staticClass:"product-title"},[_c('b-link',{staticClass:"product-link",attrs:{"href":item.link,"target":item.target}},[_vm._v(_vm._s(item.name)+" ")])],1)]),_c('div',{staticClass:"product-link"},[_c('a',{staticClass:"product-link",attrs:{"href":item.link,"target":item.target}},[_vm._v("Shop Now")])])]}},{key:"loadMore",fn:function(ref){
                  var isLastPage = ref.isLastPage;
                  var refineNext = ref.refineNext;
return [_c('div',{staticClass:"loadMore-container"},[_c('button',{staticClass:"loadMore",attrs:{"disabled":isLastPage},on:{"click":refineNext}},[_vm._v(" View More "),_c('b-icon',{attrs:{"icon":"plus-circle"}})],1)])]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
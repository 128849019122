<template>
  <div class="products">
    <PageHeader heading="Favorite Products" subheading="We have a ton of products we purchased that we absolutely love! We put together a list of our favorite dog treats, home items, toys, grooming accessories, and more. We hope you can find something for your pup!"/>
          <AllProducts/>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import AllProducts from '@/components/AllProducts.vue'

export default {
  name: "pageProducts",
  components: {
    PageHeader,
    AllProducts,
  },
 data() {
    return {
    }
    },
          metaInfo: {
      title: 'Favorite Products',
      meta: [
      { vmid: 'description', name: 'description', content: 'My favorite pet products from toys to treats to even products for your home' },
    ]
    }

}
</script>
<style scoped>

</style>
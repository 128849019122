<template>
  <div class="container" id="about">
    <div class="row about-row">
        <div class="col-sm-6 text-center">
          <picture>
  <source srcset="../assets/about.webp" type="image/webp">
  <source srcset="../assets/about.jpg" type="image/jpeg"> 
      <img src="../assets/about.jpg" class="img-fluid" alt="Mozzie the Mini Goldendoodle" width="400" height="500" loading="lazy">
          </picture>
    </div>
    <div class="col-sm-6">
    <h2>A little about me</h2>
    <div>
      <p>Hey there! My name is Mozzie and I'm an F1B Mini Goldendoodle. I'm currently {{new Date().getFullYear() - 2021}} years old and I was born on New Year's Eve. I am originally from Utah but now living on the East Coast, right here in Connecticut! I can't wait to take you along on my journey & show you all of my favorite <router-link to="/products">products</router-link> and <router-link to="/partners">companies</router-link> so you can enjoy them with me.</p>
      <p>
      We are continously adding new products & recommendations so be sure to check back soon! Also, you can follow me on my <a href="https://instagram.com/mozzietheminidood/" target="_blank">Instagram</a> to see what I've been up to!</p>
           <router-link to="/about" class="text-button">More About Me<b-icon icon="arrow-right"/></router-link>
    </div>
    </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAbout',
  props: {
  },
  data() {
    return {
  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  margin-top: 5em;
  margin-bottom:4em;
  align-items: center;
}
.img-fluid {
  max-height: 500px;
  object-fit: cover;
}
@media (max-width: 767px) {
img {
  margin-top: 2em;
  margin-bottom: 2em;
}
.col-sm-6 {
  text-align: center;
}
.about-row {
  flex-direction: column-reverse;
}
.text-button {
  justify-content: center;
}
}
</style>

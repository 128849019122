<template>
  <ais-instant-search index-name="Products" :search-client="searchClient">
        <app-loading-indicator />
    <div class="container product-container" id="products">
    <div class="search-container" ref="filtered">
      <ais-configure :hitsPerPage="100" />
      <div class="facets">
      <ais-refinement-list attribute="category" :sort-by="['isRefined']">
        <template v-slot="{ items, refine }">
          <ul class="ais-RefinementList-list">
            <li
              v-for="item in items"
              :key="item.value"
              @click="refine(item.value);goto('filtered')"
              :class="
                item.isRefined
                  ? item.label + ' ais-RefinementList-item active'
                  : 'ais-RefinementList-item'
              "
            >            
              {{ item.label }}        
            </li>
          </ul>
     </template>
      </ais-refinement-list>
            <ais-clear-refinements>
  <template v-slot="{ canRefine, refine }">
    <div v-if="canRefine" class="clear-refinements-container" @click="goto('filtered');">
            <button @click="refine" class="ais-RefinementList-item clear-refinements">Clear</button>
    </div>
    <div v-else ></div>
  </template>
</ais-clear-refinements></div>
<div class="product-list">
      <ais-infinite-hits>
        <template v-slot:item="{ item }">
          <div v-if="item.category" :class="'product-type ' + item.category">
            {{ item.category }}
          </div>
          <div class="product-image">
            <b-link :href="item.link" :target="item.target" class="product-link">
              <img class="img-fluid" :src="item.image" loading="lazy" width="200" height="200" :alt="item.name"
            /></b-link>
          </div>
          <div class="product-body">
                       
 <h4 class="product-title"><b-link :href="item.link" :target="item.target" class="product-link">{{ item.name }} </b-link></h4>
          </div>
          <div class="product-link">
            <a :href="item.link" :target="item.target" class="product-link">Shop Now</a>
          </div>
        </template>
          <template v-slot:loadMore="{ isLastPage, refineNext }">
    <div class="loadMore-container">
    <button class="loadMore"
      :disabled="isLastPage"
      @click="refineNext"
    >
      View More <b-icon icon="plus-circle"/>
    </button>
    </div>
  </template>
      </ais-infinite-hits>
</div>
    </div>
    </div>
  </ais-instant-search>
</template>



<script>
import algoliasearch from "algoliasearch/lite";

export default {
  name: 'HomeProducts',
  data() {
    return {
      searchClient: algoliasearch(
        "FZTVNY7QVX",
        "61b9df818f94a9a5f5934dc1441587b0"
      ),
    };
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0,top);
    }
  }
};
</script>
<style>
.product-container {
  margin-top: 3em;
  margin-bottom: 5em;
}

.facets {
      display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1em;
    background-color: #fff;
    padding: 1.5em 0;
    position: sticky;
    top: 0;
    z-index: 9999;
}
.ais-RefinementList {
  display: inline-flex;
  padding: 0!important;
}
.ais-RefinementList-list {
  display: flex;
  gap: 1em;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ais-RefinementList-item {
  font-size: 0.7em;
  background-color: #f4f4f4;
  color: #000;
  padding: 8px 12px;
  border-radius: 50px;
  z-index: 999;
  text-transform: uppercase;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  border: 0px;
}
.ais-RefinementList-item {
  color: #000;
}
.clear-refinements {
  background-color: #666;
  color: #fff;
}
.clear-refinements-container {
      border-left: 1px solid #d8d8d8;
    padding-left: 12px;
}
.active {
  font-weight: bold;
  color: white!important;
}
.ais-InfiniteHits-list {
  display: grid;
  grid-gap: .6em;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1em;
  margin-bottom: 1em;
  flex-direction: row;
  flex-wrap: wrap;
}
.ais-InfiniteHits-item {
  position: relative;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #f4f4f4;
  gap: 1em;
  border-radius: 10px;
}
.product-type {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 0.7em;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 50px;
  z-index: 999;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
}
.treats {
  background-color: var(--orange);
}
.accessories {
  background-color: var(--green);
}
.grooming {
  background-color: var(--purple);
}
.home {
  background-color: var(--red);
}
.toys {
  background-color: var(--blue);
}
.puppy {
  background-color: var(--teal);
}
.product-image img {
  max-height: 200px;
  max-width: 200px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  -o-object-fit: contain;
  object-fit: contain;
  mix-blend-mode: multiply;
}
.product-body {
  margin: 1em auto 0 auto;
  max-width: 400px;
}
.product-body h4 {
  font-size: 1.4em;
  text-transform: capitalize;
}
.product-title a {
  color: #2c3e50!important;
}
.product-body p {
  font-size: 1em;
  margin: .8em 0 0 0;
}
.loadMore-container {
  display: flex;
    flex-direction: row;
    justify-content: center;
    width:  100%;
}
.loadMore {
  background: none;
    border: 0;
    color: var(--primary);
    display: flex;
    gap: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.loadMore svg {
  margin-left: 2px;
}
.loadMore:disabled {
  display: none;
}
@media (max-width: 767px) {
  .ais-InfiniteHits-list {
      grid-template-columns: repeat(2, 1fr);

  }
  .ais-InfiniteHits-item {
        padding: 2em 1em;
  }
  .ais-RefinementList-list {
    justify-content: flex-start;
  }
  .ais-RefinementList {
    overflow: scroll;
  }
  .ais-RefinementList::-webkit-scrollbar {
    display: none;
} 
.product-type {
position: initial;
}
.product-body h4 {
  font-size: 1em
}
}
</style>

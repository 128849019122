<template>
  <div class="page-header">
      <div class="container">
          <div class="row">
              <div class="col-sm-12 text-center">
    <h1>{{ heading }}</h1> <h2 v-if="subheading">{{subheading}}</h2>
              </div></div></div>
  </div>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    heading: String,
    subheading: String,

  }
}
</script>
<style>
.page-header {
    background-color: #f4f4f4;
    padding: 4em 0;
}
.page-header h1 {
    font-size: 3em;
    font-weight: 600;
    margin: 0 auto;
    max-width: 1000px;
}
.page-header h2 {
    font-size: 1.3em;
    font-weight: 400;
    margin: 1em auto 0 auto;
    max-width: 1000px;
    }
@media (max-width: 767px) {
.page-header h1 {
    font-size: 2em;
}
}
</style>
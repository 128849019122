import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Partners from '../views/Partners.vue'
import Products from '../views/Products.vue'
import About from '../views/About.vue'
import BlogListing from '../views/BlogListing.vue'
import BlogPost from '../views/BlogPost.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/products',
        name: 'Products',
        component: Products
    },
    {
        path: '/partners',
        name: 'Partners',
        component: Partners
    },
    {
        path: '/blog',
        name: 'BlogListing',
        component: BlogListing
    },
    {
        path: '/blog/:slug',
        name: 'BlogPost',
        component: BlogPost
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router
<template>
 <div class="container" id="instagram">
    <div class="row header-row">
    <div class="col-sm-12 text-center">
    <h2>Follow along on Instagram <b-icon icon="instagram"></b-icon></h2>
    <h5><a href="https://instagram.com/mozzieandmia" target="_blank">@mozzieandmia</a></h5>
    </div>
    </div>
    <div class="row">
      <div class="col-sm-12 carousel-conatiner">
        <VueSlickCarousel v-bind="settings" v-if="instafeed.length > 0">
      <div v-for="item in instafeed" :key="item.id" class="carousel-item">
          <a :href="item.permalink" target="_blank">
              <img :src="item.media_url" class="carousel-img" loading="lazy"/>
          </a>
          <div class="ig-caption">{{item.caption}}</div>
    </div>
    </VueSlickCarousel>
  </div>
    </div>
    </div>
</template>
 
<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  
  export default {
    name: 'InstaCarousel',
    components: { VueSlickCarousel },
    data() {
     return {
       settings: {
  "infinite": true,
  "arrows": true,
  "speed": 300,
  "slidesToShow": 3,
  "autoplay": true,
  "focusOnSelect": true,
  "pauseOnFocus":true,
  "responsive": [
    {
      "breakpoint": 767,
      "settings": {
        "centerMode": true,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": false,
        "arrows": false,
        "focusOnSelect": false,
      }
    }
  ]
},
            instafeed: [],

  };
},
methods: {
 getInsta() {
   var key = process.env.VUE_APP_INSTA_KEY;
      fetch(
        `https://graph.instagram.com/4559435574186003/media?access_token=${key}&fields=permalink,caption,media_url`
        )
      .then(response => response.json())
      .then(data => ( 
        this.instafeed = data.data
        ));
      },
},
created() {
  this.getInsta();
 },
}
</script> 
<style scoped lang="scss">
.container {
  margin-top: 4em;
  margin-bottom: 5em;
}
.carousel-conatiner {
  padding: 0 30px;
}
 .carousel-img {
margin-bottom: 1em;
     object-fit: cover;
    aspect-ratio: 4/5;
   width: 100%;
   height: auto;
 }
 .carousel-item {
   padding: .5em;
 }
.header-row {
  margin-bottom: 1em;
}
.ig-caption {
  font-size: 13px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

</style>
<template>
  <div class="blog-listing">
    <PageHeader heading="Mini Dood Blog"/>
    <div class="container">
      <div class="blog-list">
     <div class="row blog-post-row" v-for="post in posts" :key="post.fields.slug">
        <div class="col-sm-3">
          <router-link :to="'/blog/' + post.fields.slug">
         <img :src="post.fields.featuredImage.fields.file.url" class="img-fluid blog-list-featured-img" :alt="post.fields.title">
          </router-link>
        </div>
        <div class="col-sm-9">
          <div class="blog-post-title">
          <router-link :to="'/blog/' + post.fields.slug"><h2 class="post-title">{{post.fields.title}}</h2></router-link>
          </div>
          <p> {{post.fields.excerpt}}</p>
          <router-link :to="'/blog/' + post.fields.slug" class="blog-post-link text-button">Read More</router-link>
        </div>
    </div>
      </div>
  </div>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { client } from "@/contentful"

export default {
  name: "blogListing",
  components: {
    PageHeader,
  },
 data() {
      return {
        page_title: 'Blog',
        posts: [],
      }
    },
    methods: {
      getPosts() {
        client.getEntries({
          content_type: 'blogPost',
        })
        .then((res) => {
        console.log(res.items);
        this.posts = res.items;
  });
      }
    },
    created() {
      this.getPosts()
    },
           metaInfo: {
      title: 'Mini Dood Blog',
      meta: [
      { vmid: 'description', name: 'description', content: 'Stay up to date on some great tips and tricks for your goldendoodle' },
    ]
            },
    }
</script>
<style scoped>
.blog-list {
  margin: 6em 0;
}
.blog-post-row {
  margin-bottom: 6em;
}
.blog-post-title a h2{
text-transform: capitalize;
font-size: 28px;
color: #2c3e50;
}
.blog-list img {
  aspect-ratio: 1/1;
  object-fit: cover;
  margin-bottom: 2em;
  
}
</style>
<template>
  <div class="about">
    <PageHeader heading="About Me"/>
    <div class="container">
       <div class="row about-row">
    <div class="col-md-6">
    <h2>Mozzie the Mini Dood</h2>
    <div>
        <p>Hey there! My name is Mozzie and I'm an F1B Mini Goldendoodle from Connecticut. I'm currently {{new Date().getFullYear() - 2021}} years old and fun fact- I was born on New Years Eve! My parents are Matt and Sam and even thought I constantly drive them nuts (Apparently I'm a "theif", whatever that means), they say their lives wouldn't be the same without me.
      <p>
        I'm lucky to have a lot friends, both humans and pups and love to play with them every chance I get. Everyone laughs at my smile, but I cant help it, I get so excited when I see them! I also love going to the beach and swimming, chasing my tail, snuggling with my parents, and being a goofball.
       </p>
        <p>
          My parents and I want to show you all of my favorite stuff so you can enjoy them just as much as I do. I hope you find some really great things you'll love. We are continously adding new products, so be sure to check back soon!
                 </p>
    </div>
    <div class="inline-buttons">
    <router-link to="/products" class="text-button button-inline">My Favorite Products<b-icon icon="arrow-right"/></router-link>
    <router-link to="/partners" class="text-button button-inline">Our Partners<b-icon icon="arrow-right"/></router-link>
    </div>
    </div>
 <div class="col-md-6 text-center about-img">
          <picture>
  <source srcset="../assets/about-1.webp" type="image/webp">
  <source srcset="../assets/about-1.jpg" type="image/jpeg"> 
      <img src="../assets/about-1.jpg" class="img-fluid" alt="About Mozzie the Mini Goldendoodle" height="562" width="450">
          </picture>
                    <div class="mt-4">
     <h5>Follow along on Instagram <b-icon icon="instagram"></b-icon></h5>
    <p><a href="https://instagram.com/mozzietheminidood" target="_blank">@mozzietheminidood</a></p>
    </div>
    </div>
    </div>
    <div class="row faq-row">
        <div class="heading-row">
      <div class="col-sm-12 text-center">
        <h2>Frequently Asked Questions</h2>
      </div>
    </div>
      <div class="col-sm-12">
      <FaqsSection/>
      </div>
      </div>
  </div>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import FaqsSection from '@/components/FaqsSection.vue'

export default {
  name: "aboutPage",
  components: {
    PageHeader,
    FaqsSection
  },
 data() {
    return {
  }
},
      metaInfo: {
      title: 'About Me',
      meta: [
      { vmid: 'description', name: 'description', content: 'Meet Mozzie, an F1B Mini Goldendoodle from Connecticut! Check out some of my favorite products, tips, and pictures.' },
    ]
    }
}
</script>
<style scoped>
.about-row {
  margin-top: 5em;
  align-items: top;
}
.faq-row {
  margin-bottom: 5em;
}
.col-md-6 {
  margin-bottom: 3em;
}
.about-img img{
  width: 450px;
}
.inline-buttons {
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
}
</style>
<template>
  <div class="hero">
    <div class="container">
    <div class="hero-content">
      <div class="hero-body">
    <h1> {{heading}}</h1>
      </div>
      <div class="hero-image">
        <picture>
  <source srcset="../assets/mozz-icon.webp" type="image/webp">
  <source srcset="../assets/mozz-icon.png" type="image/png"> 
  <img src="../assets/mozz-icon.png" alt="Mozzie the Mini Goldendoodle" class="img-fluid" width="480" height="480">
  </picture>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeHero',
  props: {
  },
  data() {
    return {
    "heading" : "Hey, I'm Mozzie"
  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero {
  background-color: #f4f4f4;
  color: #fff;

}
.hero img {
  width: 100%;
}
.hero-content {
  display: flex;
  align-items: center;
}
.hero-content h1{
  font-size: 120px;
  line-height: 1em;
  font-weight: 600;
  color: #2c3e50;
}
@media (max-width: 767px) {
  .hero-content h1 {
    padding-top: 1em;
font-size: 72px;
text-align: center;
  }
    .hero-content {
flex-direction: column;
  }
}
@media (max-width: 992px) {
   .hero-content h1 {
    padding-top: 1em;
font-size: 72px;
text-align: center;
   }
  .hero-content {
flex-direction: column;
  }
}
</style>

<template>
  <div id="app">
    <div class="site-content">
<SiteHeader/>
    <router-view/>
    </div>
    <SiteFooter/>
  </div>
</template>
<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'homePage',
  components: {
    SiteHeader,
    SiteFooter,
  },
  metaInfo: {
      title: 'Home',
      titleTemplate: '%s - Mozzie the Mini Goldendoodle',
      htmlAttrs: {
        lang: 'en',
        amp: true
      },
      meta: [
      { vmid: 'description', name: 'description', content: 'Mozzie the Mini Dood, an F1B Mini Goldendoodle sharing my favorite stuff!' },
    ]
    }
}
</script>
<style lang="scss">
:root {
  --primary: #0066cc!important;
}
html {
  scroll-behavior: smooth;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.site-content {
min-height: calc(100vh - 225px)}
.body {
  background-color: #fff!important;
}
p {
  font-size: 1.2rem;
}
.text-button {
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
}
.text-button svg {
  margin-left: .3em;
}
.heading-row {
  margin: 2em auto 1em auto;
  justify-content: center;
}
.heading-row .text-center {
  max-width: 700px;
}

a {
  color: var(--primary)!important;
}
a:hover {
    text-decoration: none!important;
}
.btn-primary {
  color: #fff;
  background-color: var(--primary)!important;
  border-color: var(--primary)!important;
}
.slick-prev:before {
  color: #d8d8d8!important;
}
.slick-next:before {
  color: #d8d8d8!important;
}
ol {
  padding-inline-start: 0!important;
}
</style>
